import React from 'react';
import { ViewMedium, Viewport, ViewSmall } from '@shared-ui/viewport-context';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkLayoutColumns } from 'uitk-react-layout-columns';
import { RenderVac, VirtualAgentControlComponent } from './virtual-agent-control';
import { VacLoaderComponent } from 'components/VirtualAgentControl/VacLoaderComponent';
import { VacErrorComponent } from 'components/VirtualAgentControl/VacErrorComponent';
import { IntentButton } from 'components/IntentButtons';
import { EVENTS } from 'utils/logger-events';
import { ClientLogger } from 'bernie-client';
import { GetHelpCenterConfig } from 'common/__generated__/api/types';
import { floatingVacProps } from 'components/VirtualAgentControl/virtual-agent-control-types';
import { UitkHeading } from 'uitk-react-text';

const logger = ClientLogger.getLoggerWithIdentifier('helpcenter');

type VaContainerProps = {
  data: GetHelpCenterConfig.HelpCenterPersonalizedView;
  userHasTrip?: boolean;
};

export const VaContainer: React.FC<VaContainerProps> = ({ data, userHasTrip }: VaContainerProps) => {
  const [renderVac, setRenderVac] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const isIntentButtonsEnabled = !isLoading && !error;
  const { intentButtons, vaLayoutVariant, intentButtonsHeading } = data;

  React.useEffect(() => {
    if (error) {
      removeLoader();
      setRenderVac(false);
    } else {
      setRenderVac(true);
      setIsLoading(true);
    }
  }, [error]);

  const reloadVac = () => {
    logger.logEvent(EVENTS.VAC_RELOADING, { message: 'Reloading VAC' });
    window.location.reload();
    return false;
  };

  const removeLoader = () => {
    setIsLoading(false);
  };

  if (vaLayoutVariant === 2) {
    return (
      <>
        {renderVac && (
          <RenderVac
            config={floatingVacProps}
            virtualAgentProps={{ isLoading, setError, removeLoader }}
            blankSlateLayout
            userHasTrip={userHasTrip}
          />
        )}
      </>
    );
  }

  if (vaLayoutVariant === 1) {
    return (
      <div role="region" aria-label="Quick actions">
        {intentButtons && (
          <>
            <UitkHeading tag="h3" align="left" size={5} className={`button-heading`}>
              {intentButtonsHeading}
            </UitkHeading>
            <Viewport>
              <ViewSmall>
                <UitkLayoutColumns columns={1} space={'three'}>
                  <IntentButton enabled={isIntentButtonsEnabled} data={data} />
                </UitkLayoutColumns>
              </ViewSmall>
              <ViewMedium>
                <UitkLayoutColumns columns={2} space={'three'}>
                  <IntentButton enabled={isIntentButtonsEnabled} data={data} />
                </UitkLayoutColumns>
              </ViewMedium>
            </Viewport>
          </>
        )}
        <RenderVac config={floatingVacProps} virtualAgentProps={{ isLoading, setError, removeLoader }} />
      </div>
    );
  }

  return (
    <>
      <Viewport>
        <ViewSmall>
          <UitkLayoutFlex space="two" wrap="wrap">
            <UitkLayoutFlexItem grow={1} className="flex-basis-auto">
              <div data-stid="vacContainer">
                {renderVac && (
                  <VirtualAgentControlComponent isLoading={isLoading} removeLoader={removeLoader} setError={setError} />
                )}
                <VacLoaderComponent isLoading={isLoading} />
                <VacErrorComponent error={error} reloadVac={reloadVac} />
              </div>
            </UitkLayoutFlexItem>
            <UitkLayoutFlexItem className="flex-basis-auto">
              <div className="buttons-container">
                {intentButtons && (
                  <>
                    <UitkHeading tag="h3" align="left" size={5} className={`button-heading`}>
                      {intentButtonsHeading}
                    </UitkHeading>
                    <IntentButton enabled={isIntentButtonsEnabled} data={data} />
                  </>
                )}
              </div>
            </UitkLayoutFlexItem>
          </UitkLayoutFlex>
        </ViewSmall>
        <ViewMedium>
          <UitkLayoutFlex space="two" wrap="wrap">
            <UitkLayoutFlexItem grow={1} className="flex-basis-auto">
              <div>
                <div
                  role="region"
                  aria-label="Virtual agent"
                  id="vac-container"
                  className={`hide-vac-${isLoading}`}
                  data-stid="vacContainer"
                />
                {renderVac && (
                  <VirtualAgentControlComponent isLoading={isLoading} removeLoader={removeLoader} setError={setError} />
                )}
                <VacLoaderComponent isLoading={isLoading} />
                <VacErrorComponent error={error} reloadVac={reloadVac} />
              </div>
            </UitkLayoutFlexItem>
            {intentButtons !== null && (
              <>
                <UitkLayoutFlexItem className="flex-basis-auto">
                  <div className={'gap-between'} />
                </UitkLayoutFlexItem>
                <UitkLayoutFlexItem className="flex-basis-auto">
                  <div className="buttons-container">
                    {intentButtons && (
                      <>
                        <UitkHeading tag="h3" align="left" size={5} className={`button-heading`}>
                          {intentButtonsHeading}
                        </UitkHeading>
                        <IntentButton enabled={isIntentButtonsEnabled} data={data} />
                      </>
                    )}
                  </div>
                </UitkLayoutFlexItem>
              </>
            )}
          </UitkLayoutFlex>
        </ViewMedium>
      </Viewport>
    </>
  );
};
